/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * Assets sorting options.
 */
export enum AssetSortOption {
  CREATED_AT_ASC = "CREATED_AT_ASC",
  CREATED_AT_DESC = "CREATED_AT_DESC",
  CURATION_STATUS_ASC = "CURATION_STATUS_ASC",
  CURATION_STATUS_DESC = "CURATION_STATUS_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  LOCATION_DESCRIPTION_ASC = "LOCATION_DESCRIPTION_ASC",
  LOCATION_DESCRIPTION_DESC = "LOCATION_DESCRIPTION_DESC",
  MARKET_NAME_ASC = "MARKET_NAME_ASC",
  MARKET_NAME_DESC = "MARKET_NAME_DESC",
  MEDIA_NAME_ASC = "MEDIA_NAME_ASC",
  MEDIA_NAME_DESC = "MEDIA_NAME_DESC",
  PANEL_STATUS_ASC = "PANEL_STATUS_ASC",
  PANEL_STATUS_DESC = "PANEL_STATUS_DESC",
  UPDATED_AT_ASC = "UPDATED_AT_ASC",
  UPDATED_AT_DESC = "UPDATED_AT_DESC",
  UPDATED_BY_ASC = "UPDATED_BY_ASC",
  UPDATED_BY_DESC = "UPDATED_BY_DESC",
}

export enum CurationStatus {
  COMPLETED = "COMPLETED",
  DRAFT = "DRAFT",
  HOLD = "HOLD",
  INACTIVE = "INACTIVE",
  NOT_FOUND = "NOT_FOUND",
  PUBLISHED = "PUBLISHED",
  REVIEW = "REVIEW",
  UNAVAILABLE = "UNAVAILABLE",
}

export enum DataOrigin {
  IMS = "IMS",
  MAD = "MAD",
}

export enum DistanceUnit {
  FEET = "FEET",
  MILES = "MILES",
}

export enum Format {
  DIGITAL = "DIGITAL",
  STATIC = "STATIC",
}

export enum IlluminationPeriod {
  EIGHTEEN_HOURS = "EIGHTEEN_HOURS",
  TWELVE_HOURS = "TWELVE_HOURS",
  TWENTY_FOUR_HOURS = "TWENTY_FOUR_HOURS",
}

export enum LocationType {
  BLOCK_GROUP = "BLOCK_GROUP",
  CBSA = "CBSA",
  CENSUS_TRACT = "CENSUS_TRACT",
  COUNTY = "COUNTY",
  CSA = "CSA",
  DMA = "DMA",
  NEIGHBORHOOD = "NEIGHBORHOOD",
  PLACE = "PLACE",
  STATE = "STATE",
  ZIP_CODE = "ZIP_CODE",
}

/**
 * Market sorting options.
 */
export enum MarketsSortOption {
  NAME_ASC = "NAME_ASC",
  NAME_DESC = "NAME_DESC",
  UPDATED_AT_ASC = "UPDATED_AT_ASC",
  UPDATED_AT_DESC = "UPDATED_AT_DESC",
}

/**
 * Media Type field options.
 */
export enum MediaTypeField {
  MEDIA_NAME = "MEDIA_NAME",
  MEDIA_NAME_SYNONYMS = "MEDIA_NAME_SYNONYMS",
}

export enum Orientation {
  LEFT = "LEFT",
  RIGHT = "RIGHT",
}

/**
 * Type of curation activity performed.
 */
export enum PanelCurationEventType {
  CREATE = "CREATE",
  DELETE = "DELETE",
  UPDATE = "UPDATE",
}

/**
 * Panels sorting options.
 */
export enum PanelSortOption {
  CREATED_AT_ASC = "CREATED_AT_ASC",
  CREATED_AT_DESC = "CREATED_AT_DESC",
  CURATION_STATUS_ASC = "CURATION_STATUS_ASC",
  CURATION_STATUS_DESC = "CURATION_STATUS_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  LOCATION_DESCRIPTION_ASC = "LOCATION_DESCRIPTION_ASC",
  LOCATION_DESCRIPTION_DESC = "LOCATION_DESCRIPTION_DESC",
  MARKET_NAME_ASC = "MARKET_NAME_ASC",
  MARKET_NAME_DESC = "MARKET_NAME_DESC",
  MEDIA_NAME_ASC = "MEDIA_NAME_ASC",
  MEDIA_NAME_DESC = "MEDIA_NAME_DESC",
  PANEL_STATUS_ASC = "PANEL_STATUS_ASC",
  PANEL_STATUS_DESC = "PANEL_STATUS_DESC",
  UPDATED_AT_ASC = "UPDATED_AT_ASC",
  UPDATED_AT_DESC = "UPDATED_AT_DESC",
  UPDATED_BY_ASC = "UPDATED_BY_ASC",
  UPDATED_BY_DESC = "UPDATED_BY_DESC",
}

export enum PanelStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  UNAVAILABLE = "UNAVAILABLE",
}

/**
 * Sort options for curation events search results.
 */
export enum PanelsCurationEventsSort {
  PUBLISHED_ASC = "PUBLISHED_ASC",
  PUBLISHED_DESC = "PUBLISHED_DESC",
}

/**
 * The operator to be used with a Lucene query. AND or OR.
 */
export enum QueryOperator {
  AND = "AND",
  OR = "OR",
}

/**
 * Road Segment source options.
 */
export enum RoadSegmentSource {
  CLIPPED = "CLIPPED",
  DEFAULT = "DEFAULT",
  MAD_CURATED = "MAD_CURATED",
  RAW = "RAW",
  TRUFACTOR = "TRUFACTOR",
}

/**
 * Authorization system roles for users.
 */
export enum SystemRole {
  ADMIN = "ADMIN",
  ALL = "ALL",
  DMP_EDITOR = "DMP_EDITOR",
  DMP_GUEST = "DMP_GUEST",
  MAD_CURATOR = "MAD_CURATOR",
  MAD_EDITOR = "MAD_EDITOR",
  MAD_MARKET_OWNER = "MAD_MARKET_OWNER",
  MAD_PUBLISHER = "MAD_PUBLISHER",
  PAD_EDITOR = "PAD_EDITOR",
}

export interface KeywordAutoCompleteInput {
  input: string;
  weight: number;
}

export interface KeywordCreateInput {
  category: string;
  description: string;
  name: string;
  type: string;
}

export interface KeywordUpdateInput {
  autoComplete?: KeywordAutoCompleteInput[] | null;
  category: string;
  description: string;
  hits?: number | null;
  keywords?: string[] | null;
  lastUsed?: string | null;
  name: string;
  queryDef: string;
  type: string;
}

/**
 * New panel input data
 */
export interface PanelCreateInput {
  curationNotes?: string | null;
  curationStatus: CurationStatus;
  dataOrigin?: DataOrigin | null;
  facing?: string | null;
  format: Format;
  gtReferenceNumber?: string | null;
  inventoryNumber: string;
  location: PanelLocationCreateInput;
  locationDescription: string;
  marketCode: string;
  marketingImageAcceptable?: boolean | null;
  marketingImageUrl?: string | null;
  mediaName: string;
  panelStatus: PanelStatus;
  prime?: boolean | null;
}

/**
 * Curated asset data.
 */
export interface PanelCurationEventObjectInput {
  id: string;
  name?: string | null;
  type: string;
  url: string;
}

/**
 * New location input data
 */
export interface PanelLocationCreateInput {
  bearing?: number | null;
  center: PointCreateInput;
  crossStreet?: string | null;
  primaryStreet?: string | null;
  viewshed?: PolygonCreateInput | null;
}

/**
 * Update panel data.
 */
export interface PanelUpdateInput {
  curationNotes?: string | null;
  curationStatus?: CurationStatus | null;
  facing?: string | null;
  gtReferenceNumber?: string | null;
  location?: PanelLocationCreateInput | null;
  marketingImageAcceptable?: boolean | null;
  marketingImageUrl?: string | null;
  mediaCategory?: string | null;
  mediaNameSynonyms?: string[] | null;
  mediaSubCategory?: string | null;
  prime?: boolean | null;
}

/**
 * New Point input data
 */
export interface PointCreateInput {
  coordinates: number[];
  type: string;
}

/**
 * New Polygon input data
 */
export interface PolygonCreateInput {
  coordinates: number[][][];
  type: string;
}

/**
 * Update RoadSegment input data
 */
export interface RoadSegmentsInput {
  data: any;
  source: RoadSegmentSource;
}

export interface UploadInput {
  name: string;
  path?: string | null;
  rename?: string | null;
  type?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
