import {feature, featureCollection, point, polygon} from '@turf/helpers';

import {createRoad, getRoad, updateRoad} from './graphql/RoadPanelService';

import type {RoadPanel} from 'models';
interface IPanelProps {
  id: string;
  location: {
    bearing: number;
    center: {
      type: string;
      coordinates: GeoJSON.Point['coordinates'];
    };
    viewshed: GeoJSON.Polygon | GeoJSON.MultiPolygon;
  };
}

export class RoadPanelService {
  static getInstance() {
    return new RoadPanelService();
  }

  private emptyRoad = ({id, location}: IPanelProps): RoadPanel => {
    const [lon, lat] = location.center.coordinates;
    const viewshed = this.getPolygonViewshed(location.viewshed);

    return {
      id,
      data: featureCollection([
        point(location.center.coordinates, {type: 'panel', id: id, bearing: location.bearing}),
        polygon(viewshed.coordinates, {type: 'viewshed'}),
      ]),
      center: {lat, lon},
    };
  };

  getRawData = async (panel: IPanelProps) => {
    try {
      const resp = await getRoad(panel.id, 'CLIPPED');
      return this.parsePanel(resp, panel);
    } catch (err) {
      if (err.response?.errors?.[0]?.extensions?.code === '404') {
        return this.parsePanel(this.emptyRoad(panel), panel);
      }
      throw err;
    }
  };

  getCurated = async (panel: IPanelProps) => {
    try {
      const resp = await getRoad(panel.id, 'MAD_CURATED');
      return this.parsePanel(resp, panel);
    } catch (err) {
      if (err.response?.errors?.[0]?.extensions?.code === '404') {
        return null;
      }
      throw err;
    }
  };

  updateCurated = async (road: RoadPanel) => {
    return updateRoad(road, 'MAD_CURATED');
  };

  createCurated = async (road: RoadPanel) => {
    return createRoad(road, 'MAD_CURATED');
  };

  private parsePanel = (road: RoadPanel, panel: IPanelProps): RoadPanel => {
    const [lon, lat] = panel.location.center.coordinates;
    road.center = {lat, lon};

    const features = road.data.features.map((feat) => {
      const type = feat.properties!.type;
      switch (type) {
        case 'panel':
          return feature(feat.geometry, {...feat.properties, bearing: panel.location.bearing});
        case 'viewshed':
          const viewshed = this.getPolygonViewshed(panel.location.viewshed);
          const geometry = polygon(viewshed.coordinates).geometry;

          return feature(geometry, {...feat.properties, id: panel.id});
        default:
          return feat;
      }
    });

    road.data = featureCollection(features);

    return road;
  };

  getPolygonViewshed = (viewshed: GeoJSON.Polygon | GeoJSON.MultiPolygon): GeoJSON.Polygon => ({
    type: 'Polygon',
    coordinates: viewshed.type === 'MultiPolygon' ? viewshed.coordinates[0] : viewshed.coordinates,
  });
}
